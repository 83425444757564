import * as DB from 'interfaces/database';

type OfferingGroup = 'Agendas' | 'BasicProducts' | 'BuildOptions' | 'BookTypes' | 'Cards' | 'CardGame' | 'DoubleSidePrints' | 'PhotoSheets' | 'PageSets' | 'PhotoBooks' | 'PhotoFrameBox' | 'PhotoPrints' | 'PrintTypes' | 'SingleLayer' | 'WallDecoration';

// Define constants based on ProductGroups (as defined in productgroup table in order fulfillment database)
const PG_BOOKS = 101;
const PG_POSTCARDS = 102;
const PG_SINGLE_SIDED_PRINTS = 103;
const PG_ONE_PAGERS = 104;
const PG_DOUBLE_SIDED_PRINTS = 105;
const PG_PANEL_CARDS = 106;
const PG_POSTERS = 107;
const PG_CANVAS = 108;
const PG_MULTI_MAGNETS = 109;
const PG_SINGLE_MAGNETS = 110;
const PG_YEAR_AGENDAS = 111;
const PG_MEMORY = 112;
const PG_PLAYING_CARDS = 113;
const PG_PHOTO_STRIPS = 114;
const PG_MULTI_STICKERS = 115;
const PG_CUTTING_BOARD = 116;
const PG_LUNCHBOXES_JARS = 117;
const PG_KEY_CHAINS = 118;
const PG_PHOTO_TREE = 119;
const PG_SCHOOL_AGENDAS = 120;
const PG_LAY_FLAT_BOOKS = 121;
const PG_SOCIAL_BOOKS = 122;
const PG_SOCIAL_POSTERS = 123;
const PG_SNOWGLOBES = 124;
const PG_ALUMINIUM_WALL_ART = 125;
const PG_PERSPEX_WALL_ART = 126;
const PG_MUGS = 127;
const PG_PUZZELS = 128;
const PG_MOUSEPADS = 129;
const PG_BAGS_FANNYPACKS = 130;
const PG_CLOCKS = 131;
const PG_PLACEMATS = 132;
const PG_COASTERS = 133;
const PG_PLATES = 134;
const PG_PHOTOBLOCKS = 135;
const PG_FOREX_WALL_ART = 136;
const PG_PHOTO_FRAME_BOX = 137;
const PG_PILLOWS = 138;
const PG_MULTI_PRINTS = 139;
const PG_PHONE_CASES = 140;
const PG_BABY_ROMPER = 141;
// const PG_EBOOK = 142;
const PG_FRAMED_POSTERS = 143;
const PG_WOOD_WALL_ART = 144;
const PG_NOTEBOOKS = 145;
const PG_HEXAGONS = 146;
const PG_TSHIRTS = 147;
const PG_MULTI_ENLARGEMENTS = 148;
const PG_CHOCOLATE = 149;
const PG_GARDEN_POSTER = 150;
const PG_FRAMED_PRINTS = 151;
const PG_PENS_HIGHLIGHTERS = 152;
const PG_XPOZER = 153;
const PG_PHOTO_CARDS = 154;
const PG_PHOTOSHEETS = 155;
const PG_BOTTLES = 156;
const PG_TUMBLERS = 157;
const PG_POWERBANKS = 158;
const PG_CHARGERS_CABLES = 159;
const PG_UMBRELLAS = 160;
const PG_HEADPHONES_EARBUDS = 161;
const PG_STRESS_RELIEVER = 162;
const PG_LIP_BALM = 163;
const PG_SPEAKERS = 164;
const PG_WEBCAM_BLOCKERS = 165;
const PG_LANYARDS = 166;
const PG_FIRST_AID_KITS = 167;
const PG_EAR_PLUGS = 168;
const PG_SUNGLASSES = 169;
const PG_BLANKETS = 170;
const PG_APRONS = 171;
const PG_TOWELS = 172;
const PG_HOODIES_SWEATERS = 173;
const PG_LIGHTERS = 174;
const PG_BADGE_HOLDERS = 175;
const PG_LUGGAGE_TAGS = 176;
const PG_PONCHOS = 177;
const PG_FLASH_DRIVES = 178;
const PG_LAMPS = 179;
const PG_CANDLES = 180;
const PG_GLASSWARE = 181;
const PG_PHONE_STAND = 182;
const PG_WINE_ACCESSORIES = 183;
const PG_ICE_SCRAPERS = 184;

export const COLOR_FULL = 16777216;

export const PRINT_COLOR_SPECTRUM_FULL = [
	'srgb',
	'cmyk',
];

export const OFFERING_PRINT_EFFECTS: DB.OfferingModel['printEffect'][] = [
	'embossing',
	'metalic engraving',
	'engraving',
	'laser engraving',
];

export function OfferingGroups(
	groupid: DB.OfferingModel['groupid'],
	groupOrGroups: OfferingGroup[] | OfferingGroup,
): boolean {
	const objGroups: Record<OfferingGroup, number[]> = {
		Agendas: [
			PG_YEAR_AGENDAS,
			PG_SCHOOL_AGENDAS,
		],
		// Basic products only require the editing interface, no pages overview module
		BasicProducts: [
			PG_POSTCARDS,
			PG_ONE_PAGERS,
			PG_PANEL_CARDS,
			PG_POSTERS,
			PG_CANVAS,
			PG_MULTI_MAGNETS,
			PG_MULTI_STICKERS,
			PG_CUTTING_BOARD,
			PG_LUNCHBOXES_JARS,
			PG_KEY_CHAINS,
			PG_PHOTO_TREE,
			PG_SOCIAL_POSTERS,
			PG_SNOWGLOBES,
			PG_ALUMINIUM_WALL_ART,
			PG_PERSPEX_WALL_ART,
			PG_MUGS,
			PG_PUZZELS,
			PG_MOUSEPADS,
			PG_BAGS_FANNYPACKS,
			PG_CLOCKS,
			PG_PLACEMATS,
			PG_COASTERS,
			PG_PLATES,
			PG_FOREX_WALL_ART,
			PG_PILLOWS,
			PG_PHONE_CASES,
			PG_BABY_ROMPER,
			PG_FRAMED_POSTERS,
			PG_WOOD_WALL_ART,
			PG_NOTEBOOKS,
			PG_HEXAGONS,
			PG_TSHIRTS,
			PG_CHOCOLATE,
			PG_GARDEN_POSTER,
			PG_FRAMED_PRINTS,
			PG_PENS_HIGHLIGHTERS,
			PG_XPOZER,
			PG_PHOTO_CARDS,
			PG_BOTTLES,
			PG_TUMBLERS,
			PG_POWERBANKS,
			PG_CHARGERS_CABLES,
			PG_UMBRELLAS,
			PG_HEADPHONES_EARBUDS,
			PG_STRESS_RELIEVER,
			PG_LIP_BALM,
			PG_SPEAKERS,
			PG_WEBCAM_BLOCKERS,
			PG_LANYARDS,
			PG_FIRST_AID_KITS,
			PG_EAR_PLUGS,
			PG_SUNGLASSES,
			PG_BLANKETS,
			PG_APRONS,
			PG_TOWELS,
			PG_HOODIES_SWEATERS,
			PG_LIGHTERS,
			PG_BADGE_HOLDERS,
			PG_LUGGAGE_TAGS,
			PG_PONCHOS,
			PG_FLASH_DRIVES,
			PG_LAMPS,
			PG_CANDLES,
			PG_GLASSWARE,
			PG_PHONE_STAND,
			PG_WINE_ACCESSORIES,
			PG_ICE_SCRAPERS,
		],
		// Product for which we want to show the build options dialog
		BuildOptions: [
			PG_BOOKS,
			PG_YEAR_AGENDAS,
			PG_SCHOOL_AGENDAS,
			PG_LAY_FLAT_BOOKS,
			PG_PHOTOSHEETS,
		],
		// Products with bundled pages, a right- and left pages, pages are edited on both sides
		BookTypes: [
			PG_BOOKS,
			PG_YEAR_AGENDAS,
			PG_SCHOOL_AGENDAS,
			PG_LAY_FLAT_BOOKS,
			PG_SOCIAL_BOOKS,
		],
		Cards: [
			PG_POSTCARDS,
			PG_PANEL_CARDS,
		],
		CardGame: [
			PG_PLAYING_CARDS,
		],
		DoubleSidePrints: [
			PG_DOUBLE_SIDED_PRINTS,
			PG_MEMORY,
			PG_PHOTOSHEETS,
		],
		PhotoSheets: [
			PG_PHOTOSHEETS,
		],
		// Set of single pages, either with a fixed- or a flexible page count
		PageSets: [
			PG_SINGLE_SIDED_PRINTS,
			PG_DOUBLE_SIDED_PRINTS,
			PG_SINGLE_MAGNETS,
			PG_MEMORY,
			PG_PLAYING_CARDS,
			PG_PHOTO_STRIPS,
			PG_PHOTOBLOCKS,
			PG_PHOTO_FRAME_BOX,
			PG_MULTI_PRINTS,
			PG_MULTI_ENLARGEMENTS,
			PG_PHOTOSHEETS,
		],
		// BookType that is considered a "photo book" in the true sense of the word
		PhotoBooks: [
			PG_BOOKS,
			PG_LAY_FLAT_BOOKS,
			PG_SOCIAL_BOOKS,
		],
		// Special type of product, that is basically a print set with double sided top card
		PhotoFrameBox: [
			PG_PHOTO_FRAME_BOX,
		],
		// Photo prints (meerdagenservice)
		PhotoPrints: [
			PG_MULTI_PRINTS,
		],
		// Set of single pages with a flexible page count
		PrintTypes: [
			PG_SINGLE_SIDED_PRINTS,
			PG_DOUBLE_SIDED_PRINTS,
			PG_SINGLE_MAGNETS,
			PG_PHOTOBLOCKS,
			PG_PHOTO_FRAME_BOX,
			PG_MULTI_PRINTS,
			PG_MULTI_ENLARGEMENTS,
			PG_PHOTOSHEETS,
		],
		// Products where editing only happens at one layer (no multi-layer UI required)
		SingleLayer: [
			PG_SINGLE_SIDED_PRINTS,
			PG_DOUBLE_SIDED_PRINTS,
			PG_SINGLE_MAGNETS,
			PG_MEMORY,
			PG_PLAYING_CARDS,
			PG_PHOTOBLOCKS,
			PG_PHOTO_FRAME_BOX,
			PG_MULTI_PRINTS,
			PG_MULTI_ENLARGEMENTS,
		],
		// Canvas, posters, etc.
		WallDecoration: [
			PG_POSTERS,
			PG_CANVAS,
			PG_SOCIAL_POSTERS,
			PG_ALUMINIUM_WALL_ART,
			PG_PERSPEX_WALL_ART,
			PG_FOREX_WALL_ART,
			PG_FRAMED_POSTERS,
			PG_WOOD_WALL_ART,
			PG_HEXAGONS,
			PG_GARDEN_POSTER,
			PG_XPOZER,
		],
	};

	if (Array.isArray(groupOrGroups)) {
		return !!groupOrGroups.find((arrGroup) => objGroups[arrGroup].includes(groupid));
	}

	return objGroups[groupOrGroups].includes(groupid);
}

export function OfferingLaunchers(offeringId: number) {
	const mappings: {
		[key: number]: {
			before: boolean;
			title: string;
			once: boolean;
			route: string | null;
		};
	} = {
		12110011: {
			before: false, // Run launcher after product setup is completed
			title: 'baby-book', // Used to identify the appropriate launcher
			once: true, // Show launcher only once for each product
			route: null, // Route is dynamic, as it's based on the productid (before: false)
		},
	};

	return mappings.hasOwnProperty(offeringId) ? mappings[offeringId] : null;
}

export function OfferingCountForBulkDiscount(
	bulkModel: DB.BulkModel,
	offeringModel: DB.OfferingModel,
	printPageCount: number,
	itemCount: number,
): number {
	if (bulkModel.count === 'pages') {
		let totalPageCount = Math.max(
			offeringModel.minprintpages,
			printPageCount,
		);
		const rest = (totalPageCount - offeringModel.minprintpages + offeringModel.pageinterval) % offeringModel.pageinterval;
		totalPageCount += rest > 0 ? offeringModel.pageinterval - rest : 0;

		const packageCount = totalPageCount / (offeringModel.hasback ? 2 : 1);
		return packageCount * itemCount;
	}

	return itemCount;
}

/**
 * Function to check if an offering has a required conversion to another offering
 * This is used when a certain offering is no longer available
 * @param offeringId - The offering id to check
 * @returns The offering id to convert to, or null if no conversion is required
 */
export function OfferingConversions(offeringId: number): DB.OfferingModel['id'] | null {
	// Note: Ideally this mapping should come from the database, where you can set the new offeringid
	// in the old offering record. But for the speed of implementation for HEMA, we're using a hardcoded now
	const mappings: Record<DB.OfferingModel['id'], DB.OfferingModel['id']> = {
		10110021: 10111011,
		10110022: 10111012,
		10110031: 10111021,
		10110032: 10111022,
		10110081: 10110341,
		10110082: 10110343,
		10110131: 10110991,
		10110132: 10110992,
		10110151: 10111001,
		10110152: 10111002,
		10110351: 10111051,
		10110361: 10111061,
		10110561: 10111031,
		10110571: 10111042,
		10110611: 10110981,
		10110612: 10110982,
		10110691: 10110372,
		10110692: 10110374,
		10110701: 10110342,
		10110702: 10110344,
		10110703: 10110341,
		10110704: 10110343,
		10110711: 10110962,
		10110712: 10110964,
		10110721: 10110412,
		10110722: 10110414,
		10110731: 10110392,
		10110732: 10110394,
		10110741: 10110972,
		10110742: 10110974,
		10110751: 10110402,
		10110752: 10110404,
		10110761: 10110382,
		10110762: 10110384,
		10110763: 10110381,
		10110764: 10110383,
		10110771: 10110382,
		10110772: 10110384,
		10110781: 101103713,
		10110782: 101103722,
		10110783: 101103713,
		10110784: 101103722,
		10110785: 101103713,
		10110786: 101103722,
		10110787: 101103713,
		10110788: 101103722,
		10110789: 101103713,
		101107810: 101103722,
		101107811: 101103713,
		101107812: 101103722,
		101107813: 101103713,
		101107814: 101103722,
		101107815: 101103713,
		101107816: 101103722,
		10110791: 101103913,
		10110792: 101103922,
		10110793: 101103913,
		10110794: 101103922,
		10110795: 101103913,
		10110796: 101103922,
		10110797: 101103913,
		10110798: 101103922,
		10110799: 101103913,
		101107910: 101103922,
		101107911: 101103913,
		101107912: 101103922,
		101107913: 101103913,
		101107914: 101103922,
		101107915: 101103913,
		101107916: 101103922,
		10110801: 101109613,
		10110802: 101109622,
		10110803: 101109613,
		10110804: 101109622,
		10110805: 101109613,
		10110806: 101109622,
		10110807: 101109613,
		10110808: 101109622,
		10110809: 101109613,
		101108010: 101109622,
		101108011: 101109613,
		101108012: 101109622,
		101108013: 101109613,
		101108014: 101109622,
		101108015: 101109613,
		101108016: 101109622,
		10110811: 101104113,
		10110812: 101104122,
		10110813: 101104113,
		10110814: 101104122,
		10110815: 101104113,
		10110816: 101104122,
		10110817: 101104113,
		10110818: 101104122,
		10110819: 101104113,
		101108110: 101104122,
		101108111: 101104113,
		101108112: 101104122,
		101108113: 101104113,
		101108114: 101104122,
		101108115: 101104113,
		101108116: 101104122,
		10110821: 101103913,
		10110822: 101103922,
		10110823: 101103913,
		10110824: 101103922,
		10110825: 101103913,
		10110826: 101103922,
		10110827: 101103913,
		10110828: 101103922,
		10110829: 101103913,
		101108210: 101103922,
		101108211: 101103913,
		101108212: 101103922,
		101108213: 101103913,
		101108214: 101103922,
		101108215: 101103913,
		101108216: 101103922,
		10110831: 101109713,
		10110832: 101109722,
		10110833: 101109713,
		10110834: 101109722,
		10110835: 101109713,
		10110836: 101109722,
		10110837: 101109713,
		10110838: 101109722,
		10110839: 101109713,
		101108310: 101109722,
		101108311: 101109713,
		101108312: 101109722,
		101108313: 101109713,
		101108314: 101109722,
		101108315: 101109713,
		101108316: 101109722,
		10110841: 101104013,
		10110842: 101104022,
		10110843: 101104013,
		10110844: 101104022,
		10110845: 101104013,
		10110846: 101104022,
		10110847: 101104013,
		10110848: 101104022,
		10110849: 101104013,
		101108410: 101104022,
		101108411: 101104013,
		101108412: 101104022,
		101108413: 101104013,
		101108414: 101104022,
		101108415: 101104013,
		101108416: 101104022,
		10110851: 101103813,
		10110852: 101103822,
		10110853: 101103813,
		10110854: 101103822,
		10110855: 101103813,
		10110856: 101103822,
		10110857: 101103813,
		10110858: 101103822,
		10110859: 101103813,
		101108510: 101103822,
		101108511: 101103813,
		101108512: 101103822,
		101108513: 101103813,
		101108514: 101103822,
		101108515: 101103813,
		101108516: 101103822,
		10110861: 101103813,
		10110862: 101103822,
		10110863: 101103813,
		10110864: 101103822,
		10110865: 101103813,
		10110866: 101103822,
		10110867: 101103813,
		10110868: 101103822,
		10110869: 101103813,
		101108610: 101103822,
		101108611: 101103813,
		101108612: 101103822,
		101108613: 101103813,
		101108614: 101103822,
		101108615: 101103813,
		101108616: 101103822,
	};

	if (mappings.hasOwnProperty(offeringId)) {
		return mappings[offeringId];
	}

	return null;
}
